#profile {
    padding: 60px 10px 100px 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .userInfo {
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 15px;
        background-color: var(--white);
        padding: 10px;
        gap: 15px;
        

        .userInfoWrap {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: inherit;
            background-image: linear-gradient(45deg, var(--green), var(--black));
        }
    
        .headline {
            display: flex;
            align-items: center;
            gap: 10px;
    
            img {
                border-radius: 15px;
                width: 30%;
            }
    
            span {
                font-family: "Montserrat", sans-serif;
                font-weight: 600;
                font-size: 24px;
                line-height: 26px;
            }
    
            .name {
                color: var(--greencontrast);
            }
        }
    
        .gmail, .phone {
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
            font-weight: 400;
        }
    
        button {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            padding: 2px 15px;
            background-color: var(--greencontrast);
            color: var(--white);
            border: 1px solid var(--greencontrast);
            border-radius: 5px;
            padding: 5px 20px;
            cursor: pointer;
        }
    
        button:hover {
            background-color: var(--black);
            color: var(--white);
            border: 1px solid var(--black);
        }
    }

    .myBookings {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h2 {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: var(--greencontrast);
        }

        .bookingsWrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .booking {
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
                display: flex;
                flex-direction: column;
                position: relative;
                border-radius: 15px;
                background-color: var(--white);
                padding: 10px;
                gap: 10px;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    margin: -1px;
                    border-radius: inherit;
                    background-image: linear-gradient(45deg, var(--green), var(--black));
                }

                h4 {
                    color: var(--greencontrast);
                    font-weight: 600;
                }

                .dateTime {
                    display: flex;
                    justify-content: space-between;
                }

                .refNo {
                    background-color: var(--greencontrast);
                    color: white;
                    padding: 5px;
                    border-radius: 15px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }


    .bookingHistory {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h2 {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: var(--grayed);
        }

        .bookingsWrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .booking {
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
                display: flex;
                flex-direction: column;
                position: relative;
                border-radius: 15px;
                background-color: var(--white);
                padding: 10px;
                gap: 10px;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    margin: -1px;
                    border-radius: inherit;
                    background-image: linear-gradient(45deg, var(--grayed), var(--black));
                }

                h4 {
                    color: var(--grayed);
                    font-weight: 600;
                }

                .dateTime {
                    display: flex;
                    justify-content: space-between;
                }

                .refNo {
                    background-color: var(--grayed);
                    color: white;
                    padding: 5px;
                    border-radius: 15px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    
}