.sportsPlusDescription {
  display: flex;
  flex-direction: column;

  h1 {
    color: var(--greencontrast);
    padding: 10px 10px;
  }
  span {
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;
    border-radius: 5px;
    background-color: black;
    color: var(--white);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 10px;
  }
}

#bookingForm {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .bookingDetails {
    display: flex;
    gap: 5px;
  }

  .date, .time {
    display: flex;
    flex-direction: column;
  }

  .date {
    width: 40%!important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.Mui-readOnly.MuiInputBase-readOnly.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: var(--greencontrast);
    height: 25px;
    margin-top: -5px;
    display: none!important;
    border-radius: 5px;
  }

  .dateInner, .timeInner {
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items:center;
  }

  .dateInner {
    // input[type="date"]:before {
    //   content: attr(placeholder) !important;
    //   color: #aaa;
    //   margin-right: 0.5em;
    // }
    // input[type="date"]:focus:before,
    // input[type="date"]:valid:before {
    //   content: "";
    // }

    button {
      background-color: var(--greencontrast);
      width: 100%;
      height: 25px;
      border: none;
      border-radius: 5px;
      color: var(--white);
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 12px;
    }
    
    .textsmall {
      color: var(--black);
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 15px;
      text-transform: capitalize;
    }

    .textbig {
      color: var(--greencontrast);
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 43px;
      text-transform: uppercase;
      margin: -15px 0 0 0;
    }
  }

  .advanceCTA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    label,
    span {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 14px;
    }

    span {
      font-weight: 600;
    }

    button {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      padding: 2px 15px;
      background-color: var(--greencontrast);
      color: var(--white);
      border: 1px solid var(--greencontrast);
      border-radius: 5px;
      padding: 5px 20px;
    }

    button:hover {
      background-color: var(--black);
      color: var(--white);
      border: 1px solid var(--black);
    }
  }
}

.otherDetails {
  padding: 20px 10px 0 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .note {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: rgba(14, 198, 32, 41%);
    border-radius: 5px;
    padding: 5px 10px;
    max-width: 400px;

    svg {
      width: 20%;
    }

    p {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;

      b {
        font-weight: 600;
      }
    }
  }

  .location {
    iframe {
      width: 100%;
      height: 15 0px;
      border-radius: 5px;
    }

    span {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .contactNo {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 12px;

    a {
      color: var(--greencontrast);
    }
  }
}

.phoneIcon {
  position: fixed;
  bottom: 80px;
  max-width: 1000px;
  width: 97%;
  display: flex;
  justify-content: flex-end;
}

#sportsPlus {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 100px 0;
}
