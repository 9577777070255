.ATimeSlot {
    display: flex;
    justify-content: center;
    border: solid 1px var(--greencontrast);
    color: var(--black);
    border-radius: 15px;
    padding: 5px;
  }

.selected {
  border: solid 1px var(--greencontrast);
  color: var(--white);
  background-color: var(--greencontrast);
}

#timeSlots {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.timeInner {
  div {
    margin-bottom: 10px;
    display: flex;
    align-items: end;
    justify-content: center;

    .noTime {
      font-size: 25px;
      line-height: 31px;
      width: 100%;
      text-align: center;
      color: #00000091;
      font-family: var(--poppins);
    }
  }

  button {
    background-color: var(--greencontrast);
    width: 100%;
    height: 25px;
    border: none;
    border-radius: 5px;
    color: var(--white);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
  }

  .bigtext {
      color: var(--greencontrast);
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 41px;
      text-transform: uppercase;
  }

  .smalltext {
      color: var(--greencontrast);
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 25px;
      text-transform: uppercase;
      margin: 0 0 7px 0;
  }
}

.time {
  width: 60%;
}

.note {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(14, 198, 32, 41%);
  border-radius: 5px;
  padding: 5px 10px;
  max-width: 400px;

  svg {
    width: 20%;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    b {
      font-weight: 600;
    }
  }
}

.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding: 10px 15px;
}