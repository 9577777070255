[data-amplify-authenticator] {
    --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--greencontrast);
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-form-padding:15px 15px 15px;
    --amplify-components-button-primary-background-color: var(--greencontrast); //done
    --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--greencontrast);
    --amplify-components-tabs-item-active-border-color: var(--greencontrast); //done
    --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80); //done
    --amplify-components-tabs-item-active-color: var(--greencontrast);
    --amplify-components-button-link-color: var(--greencontrast);
    --amplify-components-heading-color: var(--white);

    [data-amplify-router] {
        margin: 0 15px 0 15px;
    }
  }


#homepage {
    padding: 60px 10px 100px 10px;
    margin: auto;
}   

/* SPORTS CHOICE */
#sportsChoice {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    padding-top: 20px;

    .sportsItem {
        background-size: cover;
        border-radius: 15px;

        .bgColor {
            background-image: linear-gradient(rgba(255, 255, 255, 0), #05490c);
            padding: 0 0 15px 15px;
            border-radius: 15px;
            height: 34vh; 
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    a {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: var(--white);
        text-decoration: none;
    }
}