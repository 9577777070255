#football {
  padding: 60px 10px 100px 10px;
  margin: auto;
}

#futsalList {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  a {
    color: var(--black);
    text-decoration: none;
  }

  .futsalItem {
    display: flex;
    gap: 30px;
    border-radius: 15px;
    position: relative;
    background-color: var(--white);

    img {
      width: 50%;
      border-radius: 15px 0 0 15px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -1px;
      border-radius: inherit;
      background-image: linear-gradient(45deg, var(--black), var(--green));
    }
  }

  .futsalDetail {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    span {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 12px;
      text-decoration: none;
    }
  }

  span.locationWithIcon {
    display: flex;
    align-items: center;
    gap: 5px;
    text-transform: capitalize;
  }
}

.searchBar {
  padding: 7px 0;
  width: 80%;
  border: none;
}

.searchBar:focus {
  outline: none;
}

.searchBarWrapper {
  display: flex;
  gap: 7px;
  position: fixed;
  top: 15px;
  align-items: center;
  border: 1px solid var(--black);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 12px;
  width: 80%;
  padding-left: 10px;
  background-color: var(--white);
  max-width: 850px;
  z-index: 1;
}
