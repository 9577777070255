#NotFound {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: -40px;

    h1 {
        text-align: center;
        padding: 0 10px;
        max-width: 500px;
    }

    a {
        display: flex;
        justify-content: center;
        text-decoration: none;
    }

    img {
        width: 85%;
    }

    button {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        padding: 2px 15px;
        background-color: var(--greencontrast);
        color: var(--white);
        border: 1px solid var(--greencontrast);
        border-radius: 5px;
        padding: 5px 20px;
        cursor: pointer;

        &:active {
            background-color: var(--black);
        }
    }
}