#hamburgerMenu {
    .hamburgerOuter {
        width: 97%;
        position: fixed;
        top: 15px;
        display: flex;
        justify-content: flex-end;
        max-width: 1000px;
    }
}

#navSlide {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100vw;
    display: flex;
    z-index: 10;

    .navigation {
        width: clamp(50%, 300px, 30%);
        height: 100vh;
        background-color: var(--black);
        flex-direction: column;
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 10px;
        box-sizing: border-box;

        .stopCTA {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
        }

        nav {
            display: flex;
            flex-direction: column;
            gap: 15px;
            height: 30%;
            justify-content: center;
        }

        a {
            font-family: "Montserrat", sans-serif;
            color: var(--white);
            cursor: pointer;
            font-size: 15px;
            line-height: 20px;
            text-decoration: none;

            &:hover {
                color: var(--green);
            }
        }

        .socialMedia {
            height: 60%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            font-family: "Montserrat", sans-serif;
            color: var(--white);
            font-size: 15px;
            gap: 10px;

            .icons {
                display: flex;
                gap: 15px;
                align-items: center;
            }
        }
    }

    .backdrop {
        width: calc(100% - clamp(30%, 300px, 50%));
        background-color: #1f2420bb;
        opacity: 0;
    }
}