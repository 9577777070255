.navbar {
  display: flex;
  justify-content: center;
  background-color: #f6fff7f5;
  border: 1px solid var(--greencontrast);
  border-radius: 15px;
  padding: 5px 15px;
  position: fixed;
  bottom: 2%;
  width: 95%;
  max-width: 1000px;
}

.navbar nav {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navbarOuterDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.HomeNavComp path, .ProfNavComp path {
  &:hover {
    fill: #008a0e!important;
  }
}
